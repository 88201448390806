import React, { useState } from 'react';
import {
  TextField,
  Button,
  Grid,
  Typography,
  Paper,
  Box,
} from '@mui/material';
import { Dialog, DialogActions, DialogContent } from '@mui/material';

import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import axios from 'axios';
import UnversalSpinner from './CommonUtilites/UniversalSpinner';

function AddItemForm() {

  const [loading, setLoading] = useState(false);
  const [item, setItem] = useState({
    name: '',
    brand: '',
    category: '',
    description: '',
    warehouseLocation: '',
    costPrice: '',
    sellingPrice: '',
    gst: '',
    discount: '',
    weight: '',
    unitOfMeasure: '',
    image: null,
    additionalImages: [],
    barCode: '',
    purchaseDate: new Date(),
    expiryDate: new Date(),
    dimensions: '',
    color: '',
    averageRating: '',
    numberOfReviews: '',
    status: '',
    createdDate: new Date(),
    lastUpdatedDate: new Date(),
    lastUpdatedBy: '',
    warrantyInfo: '',
    returnPolicy: '',
    minPurchaseQty: 0,
    availabilityStock: '',
    reorderLevel: 0,
    reorderQuantity: 0,
    soldQuantity: 0,
    viewsCount: 0,
    tags: '',
    manufacturer: '',
    modelNumber: '',
  });

  const [errors, setErrors] = useState({});
  const [response, setResponse] = useState('');
  const [imagePreview, setImagePreview] = useState(null);
  const [additionalImagePreviews, setAdditionalImagePreviews] = useState([]);
  const [imgError, setImgError] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setItem({ ...item, [name]: value });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setItem({ ...item, image: file });
    setImagePreview(URL.createObjectURL(file));
    setImgError(true);
  };

  const handleAdditionalFilesChange = (e) => {
    const files = Array.from(e.target.files);
    setItem({ ...item, additionalImages: files });
    setAdditionalImagePreviews(files.map((file) => URL.createObjectURL(file)));
    setImgError(true);
  };

  const handleDateChange = (name, date) => {
    setItem({ ...item, [name]: date });
  };

  const validateFields = () => {
    const newErrors = {};
    if (!item.name) newErrors.name = "Item name is required";
    if (!item.costPrice) newErrors.costPrice = "Cost price is required";
    if (!item.sellingPrice) newErrors.sellingPrice = "Selling price is required";
    if (!item.gst) newErrors.gst = "GST is required";
    if (!item.discount) newErrors.discount = "discount is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleCloseModal = () => {
    setOpenModal(false); 
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateFields()) return;

    const formData = new FormData();
    Object.keys(item).forEach((key) => {
      if (item[key] instanceof File) {
        formData.append(key, item[key]);
      } else if (Array.isArray(item[key])) {
        item[key].forEach((file) => formData.append(key, file));
      } else {
        formData.append(key, item[key]);
      }
    });
    setLoading(true);
    try {
      const response = await axios.post('http://13.233.178.111:8080/addNewItem', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      setOpenModal(true);
      setResponse(response.data);
      setLoading(false);
      setItem({
        name: '',
        brand: '',
        category: '',
        description: '',
        warehouseLocation: '',
        costPrice: '',
        sellingPrice: '',
        gst: '',
        discount: '',
        weight: '',
        unitOfMeasure: '',
        image: null,
        additionalImages: [],
        barCode: '',
        purchaseDate: new Date(),
        expiryDate: new Date(),
        dimensions: '',
        color: '',
        averageRating: '',
        numberOfReviews: '',
        status: '',
        createdDate: new Date(),
        lastUpdatedDate: new Date(),
        lastUpdatedBy: '',
        warrantyInfo: '',
        returnPolicy: '',
        minPurchaseQty: 0,
        availabilityStock: '',
        reorderLevel: 0,
        reorderQuantity: 0,
        soldQuantity: 0,
        viewsCount: 0,
        tags: '',
        manufacturer: '',
        modelNumber: '',
      });
      setImagePreview(null);
      setAdditionalImagePreviews([]);
    } catch (error) {
      console.error('Error adding item:', error);
      setResponse('Error adding item. Please try again.');
    }
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
      padding={3}
      bgcolor="#f4f6f8"
    >
      { loading ? (
            <div style={{ marginTop: '10px' }}>
              <UnversalSpinner />
            </div>
          ) : (
      <Paper elevation={3} style={{ padding: '30px', maxWidth: '800px', width: '100%' }}>
        <Typography variant="h5" align="center" gutterBottom>
          Add New Item
        </Typography>
        
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12} sm={6}>
              <TextField
                name="name"
                label="Item Name"
                fullWidth
                required
                value={item.name}
                onChange={handleInputChange}
                error={Boolean(errors.name)}
                helperText={errors.name}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="barCode"
                label="Barcode"
                fullWidth
                required
                value={item.barCode}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="costPrice"
                label="Cost Price"
                type="number"
                fullWidth
                required
                value={item.costPrice}
                onChange={handleInputChange}
                error={Boolean(errors.costPrice)}
                helperText={errors.costPrice}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="sellingPrice"
                label="Selling Price"
                type="number"
                fullWidth
                required
                value={item.sellingPrice}
                onChange={handleInputChange}
                error={Boolean(errors.sellingPrice)}
                helperText={errors.sellingPrice}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="gst"
                label="GST"
                type="number"
                fullWidth
                required
                value={item.gst}
                error={Boolean(errors.gst)}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="discount"
                label="Discount"
                type="number"
                fullWidth
                required
                value={item.discount}
                error={Boolean(errors.discount)}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="availabilityStock"
                label="Stock Availability"
                type="number"
                fullWidth
                required
                value={item.availabilityStock}
                onChange={handleInputChange}
              />
            </Grid>
            
            <Grid item xs={12} sm={6}>
              <TextField
                name="brand"
                label="Brand"
                fullWidth
                value={item.brand}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="category"
                label="Category"
                fullWidth
                value={item.category}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="weight"
                label="Weight"
                type="number"
                fullWidth
                required
                value={item.weight}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="unitOfMeasure"
                label="Unit of Measure"
                fullWidth
                value={item.unitOfMeasure}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="dimensions"
                label="Dimensions"
                fullWidth
                value={item.dimensions}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="color"
                label="Color"
                fullWidth
                value={item.color}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="averageRating"
                label="Average Rating"
                type="number"
                fullWidth
                value={item.averageRating}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="numberOfReviews"
                label="Number of Reviews"
                type="number"
                fullWidth
                value={item.numberOfReviews}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="status"
                label="Status"
                fullWidth
                value={item.status}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="manufacturer"
                label="Manufacturer"
                fullWidth
                value={item.manufacturer}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="modelNumber"
                label="Model Number"
                fullWidth
                value={item.modelNumber}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="returnPolicy"
                label="Return Policy"
                fullWidth
                value={item.returnPolicy}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="tags"
                label="Tags"
                fullWidth
                value={item.tags}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Purchase Date"
                  value={item.purchaseDate}
                  required
                  onChange={(date) => handleDateChange('purchaseDate', date)}
                  renderInput={(params) => <TextField fullWidth {...params} />}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={6}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Expiry Date"
                  value={item.expiryDate}
                  required
                  onChange={(date) => handleDateChange('expiryDate', date)}
                  renderInput={(params) => <TextField fullWidth {...params} />}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="description"
                label="Description"
                multiline
                rows={4}
                fullWidth
                value={item.description}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button variant="contained" component="label" fullWidth style={{ backgroundColor: imgError && item.image ? '' : 'red' }}>
                Upload Image
                <input type="file" hidden name="image" onChange={handleFileChange} required />
              </Button>
            </Grid>
            {imagePreview && (
              <Grid item xs={12}>
                <img
                  src={imagePreview}
                  alt="Preview"
                  style={{ width: '250px', maxHeight: '250px', objectFit: 'cover', borderRadius: '8px' }}
                />
              </Grid>
            )}
            <Grid item xs={12} sm={6}>
              <Button variant="contained" component="label" fullWidth style={{ backgroundColor: imgError && item.additionalImages.length > 0 ? '' : 'red' }}>
                Upload Additional Images
                <input type="file" hidden multiple name="additionalImages" required onChange={handleAdditionalFilesChange} />
              </Button>
            </Grid>
            {additionalImagePreviews.length > 0 && (
              <Grid item xs={12}>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  {additionalImagePreviews.map((image, index) => (
                    <img
                      key={index}
                      src={image}
                      alt="Additional Preview"
                      style={{ width: '100px', height: '100px', objectFit: 'cover', margin: '5px' }}
                    />
                  ))}
                </div>
              </Grid>
            )}
            <Grid item xs={12} sm={12} display="flex" justifyContent="center">
              <Button
                type="submit"
                variant="contained"
                color="primary"
                style={{ width: '100%' }}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
        {/* Modal to display response */}
        <Dialog open={openModal} onClose={handleCloseModal}>
          <DialogContent>
            <Typography variant="h6" align="center" color="textSecondary">
              {response}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseModal} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Paper>
          )}
    </Box>
  );
}

export default AddItemForm;
