import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Grid,
  Typography
} from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import axios from 'axios';
import UnversalSpinner from '../CommonUtilites/UniversalSpinner';

const ViewUpdateItemDetails = ({ item, isEditMode, onClose, onSave }) => {

  const [editableItem, setEditableItem] = useState({ ...item });
  const [updatedField, setUpdatedField] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [responseData, setResponseData] = useState('');

  // const [imagePreview, setImagePreview] = useState(null);
  // const [additionalImagePreviews, setAdditionalImagePreviews] = useState([]);
  // const [imgError, setImgError] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditableItem({ ...editableItem, [name]: value });
    setUpdatedField(true)
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      const response = await axios.patch(
        'http://13.233.178.111:8080/updateItemDetails',
        editableItem,
        { headers: { 'Content-Type': 'application/json' } }
      );
      setResponseData(response.data);
      setOpenModal(true);
      setLoading(false);
    } catch (error) {
      console.error('Error updating item:', error);
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    onSave(responseData);
  };

  // const handleFileChange = (e) => {
  //   const file = e.target.files[0];
  //   setEditableItem({ ...editableItem, image: file });
  //   setImagePreview(URL.createObjectURL(file));
  //   setImgError(true);
  // };

  // const handleAdditionalFilesChange = (e) => {
  //   const files = Array.from(e.target.files);
  //   setEditableItem({ ...editableItem, additionalImages: files });
  //   setAdditionalImagePreviews(files.map((file) => URL.createObjectURL(file)));
  //   setImgError(true);
  // };

  const handleDateChange = (name, date) => {
    setEditableItem({ ...editableItem, [name]: date });
  };

  return (
    <>
      {loading ? (
        <div style={{ marginTop: '10px' }}>
          <UnversalSpinner />
        </div>
      ) : (
        <>
          <Dialog open={Boolean(item)} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>{isEditMode ? 'Edit Item' : 'View Item Details'}</DialogTitle>
            <DialogContent dividers>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    name="name"
                    label="Item Name"
                    value={editableItem.name}
                    onChange={handleInputChange}
                    disabled={!isEditMode}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    name="barCode"
                    label="BarCode"
                    value={editableItem.barCode}
                    onChange={handleInputChange}
                    disabled={!isEditMode}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    name="brand"
                    label="Brand"
                    value={editableItem.brand}
                    onChange={handleInputChange}
                    disabled={!isEditMode}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    name="category"
                    label="Category"
                    value={editableItem.category}
                    onChange={handleInputChange}
                    disabled={!isEditMode}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    name="warehouseLocation"
                    label="Warehouse Location"
                    value={editableItem.warehouseLocation}
                    onChange={handleInputChange}
                    disabled={!isEditMode}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    name="costPrice"
                    label="Cost Price"
                    value={editableItem.costPrice}
                    onChange={handleInputChange}
                    type="number"
                    disabled={!isEditMode}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    name="sellingPrice"
                    label="Selling Price"
                    value={editableItem.sellingPrice}
                    onChange={handleInputChange}
                    type="number"
                    disabled={!isEditMode}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    name="gst"
                    label="GST"
                    value={editableItem.gst}
                    onChange={handleInputChange}
                    type="number"
                    disabled={!isEditMode}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    name="discount"
                    label="Discount"
                    value={editableItem.discount}
                    onChange={handleInputChange}
                    type="number"
                    disabled={!isEditMode}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    name="weight"
                    label="Weight"
                    value={editableItem.weight}
                    onChange={handleInputChange}
                    type="number"
                    disabled={!isEditMode}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    name="unitOfMeasure"
                    label="Unit of Measure"
                    value={editableItem.unitOfMeasure}
                    onChange={handleInputChange}
                    disabled={!isEditMode}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    name="color"
                    label="Color"
                    value={editableItem.color}
                    onChange={handleInputChange}
                    disabled={!isEditMode}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    name="dimensions"
                    label="Dimensions"
                    value={editableItem.dimensions}
                    onChange={handleInputChange}
                    disabled={!isEditMode}
                  />
                </Grid>




                <Grid item xs={12} sm={6}>
                  <TextField
                    name="averageRating"
                    label="Average Rating"
                    type="number"
                    fullWidth
                    value={editableItem.averageRating}
                    onChange={handleInputChange}
                    disabled={!isEditMode}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="numberOfReviews"
                    label="Number of Reviews"
                    type="number"
                    fullWidth
                    value={editableItem.numberOfReviews}
                    onChange={handleInputChange}
                    disabled={!isEditMode}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="status"
                    label="Status"
                    fullWidth
                    value={editableItem.status}
                    onChange={handleInputChange}
                    disabled={!isEditMode}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="manufacturer"
                    label="Manufacturer"
                    fullWidth
                    value={editableItem.manufacturer}
                    onChange={handleInputChange}
                    disabled={!isEditMode}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="modelNumber"
                    label="Model Number"
                    fullWidth
                    value={editableItem.modelNumber}
                    onChange={handleInputChange}
                    disabled={!isEditMode}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="returnPolicy"
                    label="Return Policy"
                    fullWidth
                    value={editableItem.returnPolicy}
                    onChange={handleInputChange}
                    disabled={!isEditMode}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="tags"
                    label="Tags"
                    fullWidth
                    value={editableItem.tags}
                    onChange={handleInputChange}
                    disabled={!isEditMode}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="Purchase Date"
                      value={editableItem.purchaseDate}
                      required
                      onChange={(date) => handleDateChange('purchaseDate', date)}
                      renderInput={(params) => <TextField fullWidth {...params} />}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="Expiry Date"
                      value={editableItem.expiryDate}
                      required
                      onChange={(date) => handleDateChange('expiryDate', date)}
                      renderInput={(params) => <TextField fullWidth {...params} />}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="description"
                    label="Description"
                    multiline
                    rows={4}
                    fullWidth
                    value={editableItem.description}
                    onChange={handleInputChange}
                  />
                </Grid>
                {/* <Grid item xs={12} sm={6}>
                  <Button variant="contained" component="label" fullWidth style={{ backgroundColor: imgError && item.image ? '' : 'red' }}>
                    Upload Image
                    <input type="file" hidden name="image" onChange={handleFileChange} required />
                  </Button>
                </Grid>
                {imagePreview && (
                  <Grid item xs={12}>
                    <img
                      src={imagePreview}
                      alt="Preview"
                      style={{ width: '250px', maxHeight: '250px', objectFit: 'cover', borderRadius: '8px' }}
                    />
                  </Grid>
                )}
                <Grid item xs={12} sm={6}>
                  <Button variant="contained" component="label" fullWidth style={{ backgroundColor: imgError && item.additionalImages.length > 0 ? '' : 'red' }}>
                    Upload Additional Images
                    <input type="file" hidden multiple name="additionalImages" required onChange={handleAdditionalFilesChange} />
                  </Button>
                </Grid>
                {additionalImagePreviews.length > 0 && (
                  <Grid item xs={12}>
                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                      {additionalImagePreviews.map((image, index) => (
                        <img
                          key={index}
                          src={image}
                          alt="Additional Preview"
                          style={{ width: '100px', height: '100px', objectFit: 'cover', margin: '5px' }}
                        />
                      ))}
                    </div>
                  </Grid>
                )} */}

              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose} color="primary">
                Cancel
              </Button>
              {isEditMode && (
                <Button onClick={handleSave} color="primary" variant="contained" disabled={!updatedField}>
                  Save
                </Button>
              )}
            </DialogActions>
          </Dialog>

          {/* Modal to display response */}
          <Dialog open={openModal} onClose={handleCloseModal}>
            <DialogContent>
              <Typography variant="h6" align="center" color="textSecondary">
                Update Completed
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseModal} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )
      }
    </>

  );
};

export default ViewUpdateItemDetails;
