import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Container,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  IconButton,
  Typography
} from '@mui/material';
import { Edit, Visibility } from '@mui/icons-material';
import ViewUpdateItemDetails from './view-update-details';

const ViewAllItems = () => {
  const [items, setItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);

  useEffect(() => {
    axios.get('http://13.233.178.111:8080/getItemsList')
      .then(response => setItems(response.data))
      .catch(error => console.error('Error fetching items:', error));
  }, []);

  const handleViewDetails = (item) => {
    setSelectedItem(item);
    setIsEditMode(false);
  };

  const handleEditItem = (item) => {
    setSelectedItem(item);
    setIsEditMode(true);
  };

  const handleCloseDetails = () => {
    setSelectedItem(null);
  };

  return (
    <div style={{margin: '3rem'}}>
    <Container>
      <Typography variant="h4" gutterBottom>
        Item List
      </Typography>
      <List>
        {items.map((item) => (
          <ListItem key={item.itemId} divider>
            <ListItemAvatar>
              <Avatar src={item.image} alt={item.name} />
            </ListItemAvatar>
            <ListItemText primary={item.name} secondary={item.brand} />
            <IconButton onClick={() => handleViewDetails(item)}>
              <Visibility />
            </IconButton>
            <IconButton onClick={() => handleEditItem(item)}>
              <Edit />
            </IconButton>
          </ListItem>
        ))}
      </List>

      {selectedItem && (
        <ViewUpdateItemDetails
          item={selectedItem}
          isEditMode={isEditMode}
          onClose={handleCloseDetails}
          onSave={(updatedItem) => {
            setItems(items.map(i => (i.itemId === updatedItem.itemId ? updatedItem : i)));
            handleCloseDetails();
          }}
        />
      )}
    </Container>
    </div>
  );
};

export default ViewAllItems;