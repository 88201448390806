import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import './itemCard.css';

function ItemCard({ items, addItem, removeItem, selectedItems, onViewDetails }) {

  const handleClick = (item) => {
    if (selectedItems.some(selectedItem => selectedItem.itemId === item.itemId)) {
      item.quantity = 0;
      removeItem(item.itemId);
    } else {
      item.quantity = 1;
      addItem(item);
    }
  };

  return (
    <>
      {items.map((item) => {
        const isSelected = selectedItems.some(selectedItem => selectedItem.itemId === item.itemId);
        return (
          <Col key={item.itemId} style={{ margin: '2px 0' }}>
            <Card
              onClick={() => handleClick(item)}
              className={`card ${isSelected ? 'selected' : ''}`}
            >
              <Card.Img
                variant="top"
                className="frame-size"
                src={item.image}
              />
              <Card.Body style={{ minWidth: 'max-content' }}>
                <Card.Title style={{ color: 'green' }}>{item.name}</Card.Title>
                <Card.Text className="frame-text-size">
                  <div className='original-price'>
                    ₹ {(item.sellingPrice - (item.sellingPrice * item.discount * 0.01)).toFixed(2)}
                  </div>
                  <div className='discount-price'>
                    ₹ {(item.sellingPrice).toFixed(2)}
                  </div>
                  <div className='discount'>
                    ({item.discount}% off)
                  </div>
                </Card.Text>
                {/* <CardFooter style={{ padding: '0px', backgroundColor: "#ffffff" }}>
                  {item.categoryType}
                </CardFooter> */}
              </Card.Body>
              <Card.Footer style={{ textAlign: 'end', padding: '0' }}>
  <div>
    <button
      style={{
        fontSize: '14px',
        backgroundColor: '#6c757d', 
        color: '#fff',
        border: 'none',
        borderRadius: '4px',
        padding: '8px 16px',
        cursor: 'pointer',
        transition: 'background-color 0.3s, transform 0.2s', // Smooth transition
      }}
      onClick={(e) => {
        e.stopPropagation(); // Prevent triggering the onClick for the card itself
        onViewDetails(item);
      }}
      onMouseEnter={(e) => {
        e.target.style.backgroundColor = '#5a6268'; // Slightly darker gray on hover
      }}
      onMouseLeave={(e) => {
        e.target.style.backgroundColor = '#6c757d'; // Revert to original gray
      }}
      onFocus={(e) => e.target.style.outline = 'none'} // Remove default focus outline
      onBlur={(e) => e.target.style.outline = 'none'} // Remove default focus outline
    >
      View Details
    </button>
  </div>
</Card.Footer>

            </Card>
          </Col>
        );
      })}
    </>
  );
}

export default ItemCard;
