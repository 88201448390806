import { Button, Modal } from 'react-bootstrap';
import './itemDetailsCanvas.css';

function DetailsCanvas({ item, onClose, onAddToCart }) {
  if (!item) return null;

  return (
        <Modal show={!!item} onHide={onClose} className="item-details-canvas">
            <Modal.Header closeButton>
                <Modal.Title>{item?.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="item-details-image-wrapper">
                <img
                    src={item?.image}
                    alt={item?.name}
                    className="item-details-image"
                />
                </div>
                <div className="item-details-content">
                <p><strong>Offer Price:</strong> ₹ {(item?.sellingPrice - (item?.sellingPrice * item?.discount * 0.01)).toFixed(2)}</p>
                <p><strong>Original Price:</strong> ₹ {(item?.sellingPrice).toFixed(2)}</p>
                <p><strong>Discount:</strong> {item?.discount}% off</p>
                <p><strong>Category:</strong> {item?.categoryType}</p>
                <p><strong>Description:</strong> {item?.description}</p>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>Close</Button>
                <Button variant="primary" onClick={() => { onAddToCart(item); onClose(); }}>Add to Cart</Button>
            </Modal.Footer>
            </Modal>

  );
}

export default DetailsCanvas;
